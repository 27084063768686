@import 'src/styles/common.module';

.pojectListItem {
    border: 1px solid rgba(6, 6, 6, 0.072);
    width: 465px;
    box-shadow: 0px 4px 4px -2px #18274b14;
    border-radius: 10px;
    background: white;
    padding: 20px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    cursor: pointer;
    height: 610px;

    .mapContainer {
        border-radius: 10px;
        width: 100%;
        height: 238px;
        overflow: hidden;

    }

    .titleRow{
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        margin-bottom: 4px;

        .cardTitle {
            font-size: 24px;
            font-weight: 400;
            letter-spacing: 0em;
            text-align: left;
            color: $dark-teal;
            margin: 0;
        }

        .menu{

            position: relative;
            .menuToggle{
                padding: 10px 16px;
                border-radius: 4px;
                border: 1px solid $dark-teal;
                display: flex;
                svg{
                    fill: $dark-teal;
                }

                &:hover{
                    background-color: $extra-light-teal;
                }

                &:active{
                    background-color: $light-teal;
                }
            }
            
            .menuPopup{
                background-color: $white;
                border: 1px solid $dark-teal;
                position: absolute;
                width: 260px;
                left: -264px;
                top: 0px;
                .menuContent{
                    display: flex;
                    flex-direction: column;
                    gap: 6px;
                    padding: 12px 0;

                    .menuItem{
                        cursor: pointer;
                        display: flex;
                        padding: 8px 16px;
                        align-items: center;
                        gap: 16px;

                        &Icon{
                            display: flex;
                            align-items: center;
                        }

                        &:hover{
                            background-color: $extra-light-grey;
                        }
                    }

                    .separator{
                        height: 4px;
                        width: 100%;
                        border-top: 1px solid $medium-light-grey;
                    }
    
    
                }

            }

            
        }

    }

    .cardSubTitle {
        font-size: 12px;
        font-weight: $normal;
        color: $dark-grey;
    }
    
    .headerText {
        font-size: 14px;
        font-weight: 400;
        display: flex;
        justify-content: space-between;
    }

    .testTypesWrapper {
        font-size: 10px;
        display: flex;
        gap: 8px;
        flex-direction: column;


        .testTypes{
            display: flex;
            gap: 8px;
            flex-wrap: wrap;
            flex-direction: row;
        }
    }
    &:hover {
        background: $medium-light-grey;
    }
}