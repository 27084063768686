@import 'src/styles/common.module';


.projectDefinition{
    height: 100%;
    overflow: auto;
    padding-bottom: 50px;
}

.otherActions{
    display: flex;
    gap: 10px;

    button{
        gap: 10px; 
    }
}
.formView{
    width: 500px;
    margin-top: 35px;

    &FullWidth{
        width: 100%;
    }
}
.formViewTitle{
    color: $black;
    font-size: 24px;
    border-bottom: 1px solid $dark-grey;
    margin-bottom: 40px;
}

.formSection{
    .formInputSection + .formInputSection{
        margin-top: 30px;
    }

    .formInputSection{
        &Error{
            .formInputSectionTitle{
                color: $red ;
            }
             * { 
                 border-color: $red !important;
             }
        }
    }

    
}

.formSection + .formSection{
    margin-top: 30px;
}

.formSectionTitle{
    font-size: 18px;
    color: $black;
    border-bottom: 1px solid $black;
    margin-bottom: 36px;
}

.formInputSectionTitle{
    font-size: 14px;
    color: $dark-teal;
    display: flex;
    align-items: center;
    span{
        color: $dark-grey;
    }
}

.maximumText{
    font-size: 12px;
    color: $dark-teal;
    margin-top: 5px;
}

.selectContainer{
    --select-background-color: #{$white};
}


.addButton{
    span{
        font-size: 24px;
        margin-right: 8px;
    }

    &:disabled{
        background-color: $light-grey;
        border-color: $light-grey;
        color: $white;
    }
}
