// 1. Global
// ---------
$black: #000;
$white: #fff;
$globalMargin: 32px;
$font-family: 'Poppins';

// Breakpoints
// ---------
$xSmall: 679px;
$small: 919px;
$medium: 1199px;
$large: 1535px;


// Colour Palette
// ---------
$primary-navy: #262D38;
$cyan: #46dcd3;
$extra-light-teal: #BBCBCC;
$light-teal: #7CA4A6;
$medium-light-teal: #3EA8A6;
$teal: #129B9E;
$medium-dark-teal: #129b9e;
$dark-teal: #076769;
$extra-dark-teal: #003A3C;
$extra-light-grey: #F6F6F6;
$medium-light-grey: #E6E6E6;
$light-grey: #CCC;
$grey: #999999;
$dark-grey: #666666;
$light-green: #DDFFD4;
$green: #2EAD0E;
$dark-green: #5A824D;
$dark-blue: #073A69;
$medium-blue: #CFE8FF;
$red: #AD180E;
$black: #212121;
$orange: #CF8515;
$light-orange: #FFEED3;
$light-yellow: #FFF8ED;
$light-red:  #FBEEEF ;
$blue: #073A69;
$light-blue: #EEF7FE;
$turquoise: #0FC0C7;


$grey-60: #666666;

/* stylelint-disable */
:export {
  medium-light-teal: $medium-light-teal;
  light-teal: $light-teal;
  teal: $teal;
  medium-dark-teal: $cyan;
  cyan: $medium-dark-teal;
  dark-teal: $dark-teal;
  extra-light-teal: $extra-light-teal;
  white: $white;
  black: $black;
  dark-grey: $dark-grey;
  green: $green;
  dark-green: $dark-green;
  grey: $grey;
  light-grey: $light-grey;
  medium-light-grey: $medium-light-grey;
  extra-light-grey: $extra-light-grey;
  dark-blue: $dark-blue;
  medium-blue: $medium-blue;
  red: $red;
  orange: $orange;
  light-orange: $light-orange;
  primary-navy: $primary-navy;
  blue:$blue;
  light-blue:$light-blue;
  turquoise: $turquoise;
}


// font weights
$light: 300;
$normal: 400;
$mediumBold: 500;
$semiBold: 600;
$bold: 700;

