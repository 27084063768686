@import 'src/styles/common.module';

.noDataContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 75vh;

  .content {
    display: flex;
    flex-direction: column;
    margin: 10px;
    align-items: center;

    span {
      font-size: 17px;
      font-weight: $normal;
      color: $dark-grey;
    }

    div {
      margin-top: 10px;
    }
  }

  h4 {
    font-size: 29px;
    font-weight: $normal;
  }
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  flex-wrap: wrap;

  .searchBar {
    flex: 0 0 40%;

    .searchInputContainer {
      position: relative;
      .searchIcon {
        top: 7px;
        color: $dark-teal;
        position: absolute;
        left: 15px;
      }

      .searchInput {
        padding-left: 45px;
      }
    }
  }

  .button {
    flex: 0 0 0 40%;
  }
}

.customerInfo {
  margin-bottom: 30px;
  text-align: right;

  .header {
    color: $dark-grey;
  }

  .name {
    font-size: 20px;
  }
}

.creatorName{
  font-size: 12px;
  color: $dark-grey;
}

.statusCell{
  display: flex;
  flex-direction: column;
  gap: 10px;

  p{
    color: $grey;
  }
}