@import 'src/styles/common.module';

.projectCardsList {
    display: flex;
    column-gap: 20px;
    flex-wrap: wrap;
    overflow-y: auto;
}
.emptyMessagetext {
    text-align: center;
    font-size: 16px;
    margin: 2rem 4rem;
}


.otherActions{
    margin-bottom: 20px;
    display: flex;
    gap: 10px;
}