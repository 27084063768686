.nowrap{
    display: inline-flex;
    white-space: nowrap;
    span{
        line-height: 30px;
        display: flex;
        align-self:center;
        padding:0 5px 0 0;
    }
    label {
        display: none
    }
    fieldset{
        top:0px;
        legend{display:none}
    }
}
