@import 'src/styles/common.module';


.reviewSamples {
    display: flex;
    flex-direction: column;
}

.tabsContainer {
    background-color: transparent;
    padding: 0;
    gap: 0px;
}

.tabItem {
    text-transform: capitalize;
    height: 100%;
    fill: $dark-grey;
    display: flex;
    gap: 10px;
    margin-right: 30px;
    padding-bottom: 10px;
    border-bottom: 2px solid transparent;

    svg {
        margin: 0 auto;
        display: block;
    }

}

.tabItemSelected {
    fill: $dark-teal;
    border-color: $dark-teal;
}

.reviewSamplesMap {
    height: 660px;
    display: grid;
    grid-template-columns: 1fr 300px;

    border-radius: 10px;
    overflow: hidden;
}


.restore {
    color: $grey;
    font-size: 26px !important;
}

.restoreContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    width: 30px;
    padding: 10px;
    border-radius: 8px;
    background-color: white;
}



.legend {
    max-width: 250px;
    padding: 16px;
    margin-bottom: 24px;
    margin-left: 16px;
    border-radius: 8px;
    font-size: 14px;
    background: white;
    line-height: 16px;
    color: #555;
    cursor: default;
    box-shadow: 0px 0px 80px rgba(0, 0, 0, 0.3);

    .title {
        font-size: 16px;
        font-weight: $semiBold;
        margin-bottom: 16px;
        color: #777;
    }

    label {
        padding: 0;
        margin: 0 0 8px 0;

        >span {
            padding: 0 !important;
            margin-right: 8px;
        }
    }

    .keyLabel {
        display: flex;
        align-items: center;
        margin-bottom: 8px;
        word-wrap: break-word;

        i {
            width: 18px;
            height: 18px;
            border-radius: 50%;
            margin-right: 8px;
            flex-shrink: 0;
        }
    }
}


.sidebar {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 16px;
    background-color: $white;
    overflow-y: auto;

    .top {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;

        .sidebarTitle {
            font-size: 20px;
            color: $dark-teal;
        }

        .surveyCount {
            color: $dark-grey;
            font-size: 12px;
        }
    }

    .list {
        display: flex;
        flex-direction: column;
        gap: 6px;
        overflow-y: auto;

        .listItem {
            border-radius: 4px;
            border-left: 4px solid transparent;
            background-color: $extra-light-grey;
            color: $dark-grey;
            padding: 4px 0 4px 15px;
            cursor: pointer;

            &:hover {
                background-color: $light-grey;
            }

            &Active {
                background-color: $light-grey;
            }
        }
    }
}

.reviewSamplesTable {
    min-height: 660px;
}


.sampleGroup {
    display: inline-grid;
    grid-template-columns: 20px min-content;
    gap: 8px;
    align-items: center;

    &Color {
        border-radius: 50%;
        height: 16px;
        width: 16px;
    }

    &Name {
        font-size: 14px;
        color: $dark-grey;
    }
}