// Leaflet tooltip pointer override css
.tooltip {
    font-family: 'Poppins';
    border-radius: 10px !important;
    padding: 16px !important;
    box-shadow: 0 3px 14px rgb(0 0 0 / 40%) !important;

    &::before {
        margin-left: -10px !important;
        border: 10px solid transparent !important;
        border-top-color: #fff !important;
        margin-bottom: -20px !important;
    }
}